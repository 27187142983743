@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































.SimpleButton {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  &.SimpleButton_success {
    background: #5abc67;
  }
  &.SimpleButton_fail {
    background: #ff556d;
  }
}

.SimpleButton_text {
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #0f1e30;
}
