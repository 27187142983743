@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































@import '@/assets/scss/_colors.scss';
.PaymentStatus_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.PaymentStatus_root {
  border-radius: 4px;
  border: 1px solid;
  max-width: 300px;
  width: 100%;
  &--success {
    border-color: #5abc67;
    background: rgba(90, 188, 103, 0.1);
  }
  &--fail {
    border-color: #ff556d;
    background: rgba(255, 85, 109, 0.1);
  }
}

.PaymentStatus_wrapper {
  padding: 26px 0 29px;
}

.PaymentStatus_title {
  font-size: 21px;
  text-align: center;
  margin-bottom: 20px;
  &--success {
    color: #5abc67;
  }
  &--fail {
    color: #ff556d;
  }
}
